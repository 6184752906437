import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.control.visible)?_c(VCard,{class:_vm.styles.arrayList.root,attrs:{"elevation":"0"}},[_c(VCardTitle,[_c(VToolbar,{class:_vm.styles.arrayList.toolbar,attrs:{"flat":""}},[_c(VToolbarTitle,{class:_vm.styles.arrayList.label},[_vm._v(_vm._s(_vm.computedLabel))]),(_vm.control.childErrors.length > 0)?_c('validation-icon',{attrs:{"errors":_vm.control.childErrors}}):_vm._e(),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c(VBtn,_vm._g({class:_vm.styles.arrayList.addButton,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":("Add to " + (_vm.control.label)),"disabled":!_vm.control.enabled ||
              (_vm.appliedOptions.restrict &&
                _vm.arraySchema !== undefined &&
                _vm.arraySchema.maxItems !== undefined &&
                _vm.control.data.length >= _vm.arraySchema.maxItems)},on:{"click":_vm.addButtonClick}},onTooltip),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,3001535035)},[_vm._v(" "+_vm._s(("Add to " + (_vm.control.label)))+" ")])],1)],1),_c(VCardText,[_c(VContainer,{attrs:{"justify-space-around":"","align-content-center":""}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VExpansionPanels,{attrs:{"accordion":"","focusable":""},model:{value:(_vm.currentlyExpanded),callback:function ($$v) {_vm.currentlyExpanded=$$v},expression:"currentlyExpanded"}},_vm._l((_vm.control.data),function(element,index){return _c(VExpansionPanel,{key:((_vm.control.path) + "-" + index),class:_vm.styles.arrayList.item},[_c(VExpansionPanelHeader,{class:_vm.styles.arrayList.itemHeader},[_c(VContainer,{attrs:{"py-0":""}},[_c(VRow,{style:(("display: grid; grid-template-columns: " + (!_vm.hideAvatar ? 'min-content' : '') + " auto min-content " + (_vm.appliedOptions.showSortButtons
                      ? 'min-content min-content'
                      : '')))},[(!_vm.hideAvatar)?_c(VCol,{attrs:{"align-self":"center","px-0":""}},[_c('validation-badge',{attrs:{"overlap":"","bordered":"","errors":_vm.childErrors(index)}},[_c(VAvatar,{attrs:{"size":"40","aria-label":"Index","color":"primary"}},[_c('span',{staticClass:"primary--text text--lighten-5"},[_vm._v(_vm._s(index + 1))])])],1)],1):_vm._e(),_c(VCol,{class:("text-truncate " + (_vm.styles.arrayList.itemLabel)),attrs:{"align-self":"center"}},[_vm._v(_vm._s(_vm.childLabelForIndex(index)))]),(_vm.appliedOptions.showSortButtons)?_c(VCol,{attrs:{"align-self":"center"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onTooltip = ref.on;
return [_c(VBtn,_vm._g({staticClass:"v-expansion-panel-header__icon",class:_vm.styles.arrayList.itemMoveUp,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Move up","disabled":index <= 0 || !_vm.control.enabled},nativeOn:{"click":function($event){return _vm.moveUpClick($event, index)}}},onTooltip),[_c(VIcon,{staticClass:"notranslate"},[_vm._v("mdi-arrow-up")])],1)]}}],null,true)},[_vm._v(" Move Up ")])],1):_vm._e(),(_vm.appliedOptions.showSortButtons)?_c(VCol,{attrs:{"align-self":"center"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onTooltip = ref.on;
return [_c(VBtn,_vm._g({staticClass:"v-expansion-panel-header__icon",class:_vm.styles.arrayList.itemMoveDown,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Move down","disabled":index >= _vm.control.data.length - 1 ||
                            !_vm.control.enabled},nativeOn:{"click":function($event){return _vm.moveDownClick($event, index)}}},onTooltip),[_c(VIcon,{staticClass:"notranslate"},[_vm._v("mdi-arrow-down")])],1)]}}],null,true)},[_vm._v(" Move Down ")])],1):_vm._e(),_c(VCol,{attrs:{"align-self":"center"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var onTooltip = ref.on;
return [_c(VBtn,_vm._g({staticClass:"v-expansion-panel-header__icon",class:_vm.styles.arrayList.itemDelete,attrs:{"fab":"","text":"","elevation":"0","small":"","aria-label":"Delete","disabled":!_vm.control.enabled ||
                            (_vm.appliedOptions.restrict &&
                              _vm.arraySchema !== undefined &&
                              _vm.arraySchema.minItems !== undefined &&
                              _vm.control.data.length <= _vm.arraySchema.minItems)},nativeOn:{"click":function($event){$event.stopPropagation();_vm.suggestToDelete = index}}},onTooltip),[_c(VIcon,{staticClass:"notranslate"},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)],1)],1)],1),_c(VExpansionPanelContent,{class:_vm.styles.arrayList.itemContent},[_c('dispatch-renderer',{attrs:{"schema":_vm.control.schema,"uischema":_vm.foundUISchema,"path":_vm.composePaths(_vm.control.path, ("" + index)),"enabled":_vm.control.enabled,"renderers":_vm.control.renderers,"cells":_vm.control.cells}})],1)],1)}),1)],1)],1),(_vm.noData)?_c(VContainer,{class:_vm.styles.arrayList.noData},[_vm._v(" No data ")]):_vm._e()],1),_c(VDialog,{attrs:{"value":_vm.suggestToDelete !== null,"max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.suggestToDelete = null},"click:outside":function($event){_vm.suggestToDelete = null}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Delete "+_vm._s(_vm.childLabelForIndex(_vm.suggestToDelete) || 'element')+"? ")]),_c(VCardText,[_vm._v(" The element will be deleted. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.suggestToDelete = null}}},[_vm._v(" Cancel ")]),_c(VBtn,{ref:"confirm",attrs:{"text":""},on:{"click":function($event){_vm.removeItemsClick([_vm.suggestToDelete]);
            _vm.suggestToDelete = null;}}},[_vm._v(" Delete ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }