import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"hidden-md-and-down"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardSubtitle),_c(VCardText,[_c(VContainer,{staticClass:"full-height ma-0 pa-1",staticStyle:{"height":"100%","border":"0px solid green"},attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height ma-0 pa-0",staticStyle:{"border":"0px solid red"}},[_c(VCol,{staticClass:"ma-0 pa-0",staticStyle:{"border":"0px solid red"},attrs:{"cols":"6"}},[_vm._t("monthly_graph")],2),_c(VCol,{staticClass:"ma-0 pa-0",staticStyle:{"border":"0px solid red"},attrs:{"cols":"6"}},[_vm._t("daily_graph")],2)],1)],1)],1)],1),_c(VCard,{staticClass:"hidden-lg-and-up"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardSubtitle),_c(VCardText,[_c(VContainer,{staticClass:"full-height ma-0 pa-1",staticStyle:{"height":"100%","border":"0px solid green"},attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height ma-0 pa-0",staticStyle:{"border":"0px solid red"}},[_c(VCol,{staticClass:"ma-0 pa-0",staticStyle:{"border":"0px solid red"},attrs:{"cols":"12"}},[_vm._t("monthly_graph")],2)],1),_c(VRow,{staticClass:"fill-height ma-0 pa-0",staticStyle:{"border":"0px solid red"}},[_c(VCol,{staticClass:"ma-0 pa-0",staticStyle:{"border":"0px solid red"},attrs:{"cols":"12"}},[_vm._t("daily_graph")],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }