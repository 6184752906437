import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticStyle:{"border":"0px solid pink"}},[_c(VBreadcrumbs,{attrs:{"items":_vm.paths},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-forward")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{nativeOn:{"click":function($event){return _vm.goTo(item)}}},[_c('span',{staticClass:"breadcrumb_span"},[_vm._v(_vm._s(item.text))])])]}}])}),(_vm.displayType == _vm.DisplayType.SITE_GROUPS)?_c('SiteGroups',{on:{"siteGroupClicked":_vm.onSiteGroupClicked}}):_vm._e(),(_vm.displayType == _vm.DisplayType.SITES)?_c('Sites',{attrs:{"selectedSiteGroup":_vm.selectedSiteGroup},on:{"equipmentClicked":_vm.onEquipmentClicked}}):_vm._e(),(_vm.displayType == _vm.DisplayType.POINTS)?_c('Points',{attrs:{"site_ref":_vm.selectedSite,"equip_ref":_vm.selectedEquipment,"selectedSiteGroup":_vm.selectedSiteGroup}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }