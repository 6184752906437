import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export const shimmy = {
  primary: '#1976D2',
  secondary: '#424242',
  appbar: '#FFFFFF',
  background: '#FFFFFF',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

export const carnego_theme = {
  primary: '#39613E',
  secondary: 'red',
  appbar: '#FFFFFF',
  background: '#FFFFFF',
  accent: '#009688',
  error: '#8bc34a',
  warning: '#ffeb3b',
  info: '#ffc107',
  success: '#ff5722',
};

export const ventive_theme = {
  primary: '#39613E',
  secondary: '#FFFFFF',
  appbar: '#FFFFFF',
  background: '#000000',
  accent: '#009688',
  error: '#8bc34a',
  warning: '#ffeb3b',
  info: '#ffc107',
  success: '#ff5722',
};

export default new Vuetify({
  theme: {
    themes: {
      light: ventive_theme,
    },
  },
});
