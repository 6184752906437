import { sprintf } from 'sprintf-js';
import { IRefInfo } from '@/main';
import { IChartRef, ILine, IDataPoint } from '@/components/ShimmyPlus/ChartInterfaces';
import { DateTime } from "luxon";
import base64url from 'base64url';

export function object_to_baseurl64(refs: IChartRef[]): string {
  return base64url.encode(JSON.stringify(refs));
}

export function split_namespace_name(namespace: string) {
  const lastIndex = namespace.lastIndexOf('.');
  const after = namespace.slice(lastIndex + 1);
  return after;
}

export function getBit(n: number, bitPosition: number): boolean {
    // tslint:disable-next-line:no-bitwise
    return (n & (1 << bitPosition)) === 0 ? false : true;
}

export function generateColourChannelGradient(x1: number, x2: number, y1: number, y2: number): number[] {
    const m = (y2 - y1) / (x2 - x1);
    const range = x2 - x1;
    const c = y1;

    const tmp = new Array(range);

    for (let i = 0; i <= range; i++) {
        tmp[i] = m * i + c;
    }

    return tmp;
  }

export function generateTemperatureGradient(): string[] {

    const red_channel = new Array(24);
    const green_channel = new Array(24);
    const blue_channel = new Array(24);

    for (let i = 0; i < 15; i++) {
      const red_values = Array.from({ length: 14 }).fill(0);
      const green_values = Array.from({ length: 14 }).fill(0);
      const blue_values = Array.from({ length: 14 }).fill(255);

      red_channel.splice(0, 0, ...red_values);
      green_channel.splice(0, 0, ...green_values);
      blue_channel.splice(0, 0, ...blue_values);
    }

    for (let i = 15; i < 20; i++) {
      const red_values = generateColourChannelGradient(15, 19, 0, 255);
      const green_values = generateColourChannelGradient(15, 19, 0, 191);
      const blue_values = generateColourChannelGradient(15, 19, 255, 0);

      red_channel.splice(15, 0, ...red_values);
      green_channel.splice(15, 0, ...green_values);
      blue_channel.splice(15, 0, ...blue_values);
    }

    for (let i = 21; i < 24; i++) {
      const red_values = Array.from({ length: 3 }).fill(255);
      const green_values = generateColourChannelGradient(21, 23, 191, 0);
      const blue_values = Array.from({ length: 3 }).fill(0);

      red_channel.splice(21, 0, ...red_values);
      green_channel.splice(21, 0, ...green_values);
      blue_channel.splice(21, 0, ...blue_values);
    }

    const colours = new Array(24);

    for (let i = 0; i < 24; i++) {
      colours[i] = sprintf('rgb(%d,%d,%d)', red_channel[i], green_channel[i], blue_channel[i]);
    }

    console.log(colours);
    return colours;
}

// Calculate the sum of an array.
// @param  {Array} `arr` Array
// @return {Number} Sum
export function sum(arr: IDataPoint[]): IDataPoint {
  let len = arr.length;
  let num = 0;
  while (len--) {
    num += Number(arr[len].y);
  }
  return {x: arr[arr.length - 1].x, y: num};
}

// Create an average for the specified range.

//  ```js
//  console.log(avg([1, 2, 3, 4, 5, 6, 7, 8, 9], 5, 4));
//  //=> 3.5
//  ```
//  @param  {Array} `arr` Array to pull the range from.
//  @param  {Number} `idx` Index of element being calculated
//  @param  {Number} `range` Size of range to calculate.
//  @return {Number} Average of range.
export function avg(arr: IDataPoint[], idx: number, range: number): IDataPoint {
  // const sliced_arr = arr.slice(idx - range, idx);
  // debugger;
  // const s = sum(sliced_arr);
  // return {x: s.x, y: s.y / range};

  let sum = 0.0;
  let start = Math.max(0, idx - range);
  let last = idx;
  let count = 0;
  for(let i = start; i <= last; i++) {
    sum += arr[i].y;
    count += 1;
  }

  return {x: arr[last].x, y: sum / count};

  // const sliced_arr = arr.slice(idx - range, idx);
  // debugger;
  // const s = sum(sliced_arr);
  // return {x: s.x, y: s.y / range};
}

// Default format method.
// @param  {Number} `n` Number to format.
// @return {String} Formatted number.
function toFixed(n: number) {
  return n.toFixed(2);
}


// Calculate the simple moving average of an array. A new array is returned with the average
// of each range of elements. A range will only be calculated when it contains enough elements to fill the range.

// ```js
// console.log(sma([1, 2, 3, 4, 5, 6, 7, 8, 9], 4));
// //=> [ '2.50', '3.50', '4.50', '5.50', '6.50', '7.50' ]
// //=>   │       │       │       │       │       └─(6+7+8+9)/4
// //=>   │       │       │       │       └─(5+6+7+8)/4
// //=>   │       │       │       └─(4+5+6+7)/4
// //=>   │       │       └─(3+4+5+6)/4
// //=>   │       └─(2+3+4+5)/4
// //=>   └─(1+2+3+4)/4
// ```
// @param  {Array} `arr` Array of numbers to calculate.
// @param  {Number} `range` Size of the window to use to when calculating the average for each range. Defaults to array length.
// @param  {Function} `format` Custom format function called on each calculated average. Defaults to `n.toFixed(2)`.
// @return {Array} Resulting array of averages.

// function sma(arr: IDataPoint[], range: number, format: any) {
//   if (!Array.isArray(arr)) {
//     throw TypeError('expected first argument to be an array');
//   }

//   const fn = typeof format === 'function' ? format : toFixed;
//   // const num = range || arr.length;
//   const num = (range < arr.length) ? range : arr.length;
//   const res: IDataPoint[] = [];
//   const len = arr.length + 1;
//   let idx = num - 1;
//   while (++idx < len) {
//     // res.push(fn(avg(arr, idx, num)));
//     res.push(avg(arr, idx, num));
//   }
//   return res;
// }

export function sma(arr: IDataPoint[], range: number, format: any) {
  if (!Array.isArray(arr)) {
    throw TypeError('expected first argument to be an array');
  }

  // debugger;

  // const fn = typeof format === 'function' ? format : toFixed;
  // const num = (range < arr.length) ? range : arr.length;
  // const res: IDataPoint[] = [];
  // const len = arr.length + 1;
  // let idx = num - 1;
  // while (++idx < len) {
  //   // res.push(fn(avg(arr, idx, num)));
  //   res.push(avg(arr, idx, num));
  // }
  // return res;


  const fn = typeof format === 'function' ? format : toFixed;
  const len = (range < arr.length) ? range : arr.length;
  const res: IDataPoint[] = [];

  let start = arr.length - len;

  while (start < arr.length) {
    // res.push(fn(avg(arr, idx, num)));
    let av = avg(arr, start, range);
    res.push(av);
    start++;
  }
  return res;

}

export function get_last_number_of_elements(array: any[], number_of_elements: number): any[] {
  return array.slice(-number_of_elements)
}

export function ref_info_to_ichart_refs(refs: IRefInfo[]): IChartRef[] {
  let tmp: IChartRef[] = [];

  for (let r of refs) {
    tmp.push({ ref: r.ref,
              name: r.name,
              title: r.dis,
              backgroundColor: 'rgba(29,133,147, 1.0)',
              borderColor: 'rgba(29,133,147, 1.0)',
              startDateTime: r.start_datetime });
  }

  return tmp;
}


export function dateRangeForLastDay(): string {
  const end = DateTime.now().toUTC().endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const start = DateTime.now().toUTC().startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return start + ',' + end;
}

export function dateRangeForLast10Days(): string {
  const end = DateTime.now().toUTC().endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const start = DateTime.now().toUTC().minus({days:10}).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return start + ',' + end;
}

export function dateRangeForLastMonth(): string {
  const end = DateTime.now().toUTC().endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const start = DateTime.now().toUTC().minus({days:31}).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return start + ',' + end;
}

export function dateRangeForLastHour(): string {
  const end = DateTime.now().toUTC().endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const start = DateTime.now().toUTC().minus({hours:2}).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return start + ',' + end;
}

export function dateRangeForLast12Month(): string {
  const end = DateTime.now().toUTC().endOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const start = DateTime.now().toUTC().minus({months:12}).startOf('day').toFormat("yyyy-MM-dd'T'HH:mm:ss");
  return start + ',' + end;
}

export function last(array: any[]) {

  if (array === undefined || array.length <= 0) {
    return NaN;
  }

  return array[array.length - 1];
}

export function parseCSVIntoArraysOfFields(content: string): any[string] {
  return content.split('\n')
    .map((ar) => ar.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map((refi) => refi.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '').trim()));
}

export function csv_to_datatable(csv_data: string, skip_headers_lines: number, field_convert?: (field: string, field_index: number) => any): [object[], object[]] {

    let header: object[] = [];
    let data: object[] = [];

    let parsed = parseCSVIntoArraysOfFields(csv_data);

    if (skip_headers_lines > 0) {
      parsed = parsed.slice(skip_headers_lines);
    }

    let count = 0;
    for (const field of parsed[0]) {
      header.push({text: field, units: '', align: 'center', value: count.toString()});
      count++;
    }

    parsed = parsed.slice(1); // Skip onto data

    for (const fields of parsed) {
      count = 0;

      const item_obj = {};
      for (const field of fields) {

        if (field_convert !== undefined) {
          item_obj[count.toString()] = field_convert(field, count);
        }
        else {
          item_obj[count.toString()] = field;
        }

        count++;
      }

      data.push(item_obj);
    }

    return [header, data];
  }