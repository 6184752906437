import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"border":"0px solid pink"}},[_c(VList,{staticClass:"ma-0 pa-2",staticStyle:{"border":"0px solid green"}},[_c(VSubheader,{staticClass:"title"},[_vm._v("Sites")]),_c(VIcon,{staticStyle:{"float":"right"},attrs:{"large":"","color":"green darken-2"},on:{"click":_vm.onRefresh}},[_vm._v("mdi-refresh")]),_c(VDataTable,{ref:"expandableTable",attrs:{"headers":_vm.headers,"items":_vm.data,"hide-default-footer":"","item-key":"equipment_name","single-expand":true,"items-per-page":_vm.items_per_page,"sort-by":"site","group-by":"site"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{on:{"click":function($event){return _vm.onEquipmentClick(props.item.siteref, props.item.equipref)}}},[_c('td',{staticStyle:{"width":"30%","border":"0px solid blue"}},[_c(VIcon,{staticStyle:{"float":"right"}},[_vm._v("mdi-arrow-right-thick")])],1),_c('td',{attrs:{"align":"left"}},[_c('span',[_vm._v(_vm._s(props.item.equipment_name))])]),_c('td',[_vm._v(" "+_vm._s(props.item.status)+" ")])])]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VBtn,{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-plus")]):_c(VIcon,[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold",on:{"click":function($event){return _vm.onSiteClick(group)}}},[_vm._v(_vm._s(group))])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }