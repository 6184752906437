import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("Summary Performance ("+_vm._s(_vm.last_run)+")")]),_c(VCardSubtitle),_c(VCardText,[_c(VRow,{attrs:{"row":""}},[_c(VCol,{staticClass:"s8 input-field no-margins"},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VRow,{staticClass:"hidden-md-and-down",attrs:{"row":""}},[_c(VCol,{staticClass:"s8 input-field no-margins"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"loading":_vm.showWait,"loading-text":"Loading... Please wait","items":_vm.data,"dense":"","hide-default-footer":"","hide-default-header":"","items-per-page":_vm.items_per_page,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}}),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"5"}},[_vm._v("Last 12 months")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("Last 30 days")])]),_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(header.units)+" ")])])}),0)])]}},{key:"item",fn:function(props){return [_c('tr',[_c('td',{attrs:{"align":"center"}},[(_vm.hasSpecificRef)?_c('div',[_vm._v(" "+_vm._s(props.item.site.display)+" ")]):_c('div',[_c('a',{attrs:{"href":_vm.link(props.item.site_ref)}},[_vm._v(_vm._s(props.item.site.display))])])]),_c('td',[_c('FaceRating',{attrs:{"value":props.item.issues.display}})],1),(_vm.isAdmin)?_c('td',{attrs:{"align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'bcp',
                    query: {
                      ids: props.item.carnego_heating_last_12_months.refs,
                      aggregate: 'diff',
                      resolution: 'day',
                      start_day: _vm.start_day_str,
                      end_day: _vm.end_day_str,
                    },
                  }}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_heating_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_heating_last_12_months.display)+" ")])],1)],1):_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_heating_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_heating_last_12_months.display)+" ")])],1),(_vm.isAdmin)?_c('td',{attrs:{"align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'bcp',
                    query: {
                      ids: props.item.carnego_comfort_last_12_months.refs,
                      aggregate: 'avg',
                      resolution: 'day',
                      start_day: _vm.start_day_str,
                      end_day: _vm.end_day_str,
                    },
                  }}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_comfort_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_comfort_last_12_months.display)+" ")])],1)],1):_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_comfort_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_comfort_last_12_months.display)+" ")])],1),(_vm.isAdmin)?_c('td',{attrs:{"align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'bcp',
                    query: {
                      ids: props.item.carnego_hotwater_last_12_months.refs,
                      aggregate: 'diff',
                      resolution: 'day',
                      start_day: _vm.start_day_str,
                      end_day: _vm.end_day_str,
                    },
                  }}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_hotwater_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_hotwater_last_12_months.display)+" ")])],1)],1):_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_hotwater_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_hotwater_last_12_months.display)+" ")])],1),(_vm.isAdmin)?_c('td',{attrs:{"align":"center"}},[_c('router-link',{attrs:{"to":{
                    name: 'bcp',
                    query: {
                      ids: props.item.carnego_appliance_last_12_months.refs,
                      aggregate: 'diff',
                      resolution: 'day',
                      start_day: _vm.start_day_str,
                      end_day: _vm.end_day_str,
                    },
                  }}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_appliance_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_appliance_last_12_months.display)+" ")])],1)],1):_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_appliance_last_12_months)}},[_vm._v(" "+_vm._s(props.item.carnego_appliance_last_12_months.display)+" ")])],1),_c('td',{attrs:{"align":"center"}}),_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_heating_lastmonth)}},[_vm._v(" "+_vm._s(props.item.carnego_heating_lastmonth.display)+" ")])],1),_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_comfort_lastmonth)}},[_vm._v(" "+_vm._s(props.item.carnego_comfort_lastmonth.display)+" ")])],1),_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_hotwater_lastmonth)}},[_vm._v(" "+_vm._s(props.item.carnego_hotwater_lastmonth.display)+" ")])],1),_c('td',{attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":_vm.getColor(props.item.carnego_appliance_lastmonth)}},[_vm._v(" "+_vm._s(props.item.carnego_appliance_lastmonth.display)+" ")])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }