import Vue from 'vue';
import Router from 'vue-router';
import './componentHooks'; // <-- Needs to be first
import LoginComponent from './views/Login.vue';
import Home from './views/Home.vue';
import Bcp from './views/Bcp.vue';
import TaggingManager from './views/Tagging.vue';
import UsersAdmin from './views/Admin/UsersAdmin.vue';
import About from './views/About.vue';
import DashBoard from './views/ShimmyPlus/DashBoard.vue';
import PageOne from './views/ShimmyPlus/Page1.vue';
import PerformanceManager from './views/PerformanceManager.vue';
import PortfolioManager from './views/PortfolioManager.vue';
import PortfolioManagerDetails from './views/PortfolioManagerDetails.vue';
import DataQuality from './views/DataQuality.vue';
import ShimmyPlus from '@/views/ShimmyPlus/ShimmyPlus.vue';
import SolutionManager from '@/views/SolutionManager/SolutionManager.vue';
import ScriptManager from '@/views/SolutionManager/ScriptManager.vue';
import ScriptManagerAddEdit from '@/views/SolutionManager/ScriptManagerAddEdit.vue';
// import YosemiteManager from '@/views/SolutionManager/Yosemite/YosemiteManager.vue';
// import AddEditSiteGroup from '@/views/SolutionManager/Yosemite/AddEditSiteGroup.vue';
// import AddEditSite from '@/views/SolutionManager/Yosemite/AddEditSite.vue';
// import AddEditEquipment from '@/views/SolutionManager/Yosemite/AddEditEquipment.vue';
// import AddEditPoint from '@/views/SolutionManager/Yosemite/AddEditPoint.vue';
// import ConfigDebug from '@/views/SolutionManager/Yosemite/ConfigDebug.vue';
// import YosemiteConfigDialog from '@/views/SolutionManager/Yosemite/YosemiteConfigDialog.vue';

import YosemiteConfig from '@/views/SolutionManager/Yosemite/YosemiteConfig.vue';

Vue.use(Router);

export default new Router({
  // mode: 'abstract',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/dataquality',
      name: 'dataquality',
      component: DataQuality,
    },
    {
      path: '/bcp',
      name: 'bcp',
      component: Bcp,
    },
    {
      path: '/tagging',
      name: 'tagging',
      component: TaggingManager,
    },
    { name: 'shimmyplus', path: '/shimmyplus/:id', component: ShimmyPlus},
    {
      path: '/admin/users_admin',
      name: 'users_admin',
      component: UsersAdmin,
    },
    {
      path: '/performance_manager',
      name: 'performance_manager',
      component: PerformanceManager,
    },
    {
      path: '/portfolio_manager',
      name: 'portfolio_manager',
      component: PortfolioManager,
    },
    {
      path: '/portfolio_manager_details/:id',
      name: 'portfolio_manager_details',
      component: PortfolioManagerDetails,
    },
    {
      path: '/solutionmanager/',
      name: 'solutionmanager',
      component: SolutionManager,
    },

    {
      path: '/solutionmanager/yosemite',
      name: 'YosemiteConfig',
      component: YosemiteConfig,
    },

    {
      path: '/scriptmanager/',
      name: 'scriptmanager',
      component: ScriptManager,
    },

    {
      path: '/scriptmanager/addedit',
      name: 'scriptmanager_addedit',
      component: ScriptManagerAddEdit,
    },

    {
      path: '/login',
      name: 'login',
      component: LoginComponent,
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/About.vue'),
    },
  ],
});
