import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"row":""}},[_c(VCol,{staticClass:"s8 input-field no-margins"},[(!_vm.valid)?_c('div',[_vm._v(" This page is not valid for this site group ")]):_c('div',{attrs:{"align":"center"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Summary Performance ("+_vm._s(_vm.last_run)+")")]),_c(VCardSubtitle),_c(VCardText,[_c(VRow,{staticClass:"hidden-md-and-down",attrs:{"row":""}},[_c(VCol,{staticClass:"s8 input-field no-margins"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"loading":_vm.showWait,"loading-text":"Loading... Please wait","items":_vm.data,"dense":"","hide-default-footer":"","hide-default-header":"","items-per-page":_vm.items_per_page,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(" "+_vm._s(header.text)+" "),_c('br'),_vm._v(" "+_vm._s(header.units)+" ")])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((item),function(field,i){return _c('td',{key:i,attrs:{"align":"center"}},[_c(VChip,{attrs:{"color":field[1]}},[_vm._v(_vm._s(field[0]))])],1)}),0)]}}])})],1)],1)],1)],1)],1)])],1),_c('br'),[_c('div',[_c(VBtn,{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.get_log()}}},[_vm._v("Show Log")]),_c('br'),(_vm.showLog)?_c(VCard,[_c(VCardTitle,[_vm._v("Log")]),_c(VCardSubtitle),_c(VCardText,[_c(VTextarea,{attrs:{"rows":"10"},model:{value:(_vm.log_data),callback:function ($$v) {_vm.log_data=$$v},expression:"log_data"}})],1)],1):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }