import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{style:({background: _vm.$vuetify.theme.themes[_vm.theme].background})},[_c(VAppBar,{attrs:{"app":"","clipped-left":"","dense":"","color":"appbar"}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VImg,{attrs:{"max-height":"32","max-width":"100","src":require("@/assets/ventive-logo-black_32pxhigh.png")}})],1),_c(VNavigationDrawer,{attrs:{"clipped":"","app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListItem,{staticClass:"px-2"},[(_vm.avatar)?_c(VListItemAvatar,[_c(VImg,{staticStyle:{"width":"32px","height":"32px"},attrs:{"src":_vm.avatar}})],1):_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-face")])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v(_vm._s(_vm.username))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.email))])],1)],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemContent,[_c(VListItemTitle,{on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_vm._v("Logout")])],1)],1)],1)],1),_c(VMain,[_c('router-view',{staticClass:"pa-2",on:{"authenticated":_vm.setAuthenticated}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }