import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto mt-5",attrs:{"width":"400"}},[_c(VCardTitle,[_c('h1',{staticClass:"display-1"},[_vm._v("Login")])]),_c(VCardText,[_c(VTextField,{attrs:{"id":"username","label":"Username","autocapitalize":"none","prepend-icon":"mdi-account-circle"},model:{value:(_vm.input.username),callback:function ($$v) {_vm.$set(_vm.input, "username", $$v)},expression:"input.username"}}),_c(VTextField,{attrs:{"id":"password","type":_vm.showPassword ? 'text' : 'password',"label":"Password","prepend-icon":"mdi-lock","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"id":"login_button","color":"info"},on:{"click":function($event){return _vm.login()}}},[_vm._v("Login")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }