import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"full-height ma-0 pa-1",staticStyle:{"height":"100%","border":"0px solid green"},attrs:{"fluid":""}},[_c(VCard,[_c(VCardText,[_c(VSelect,{attrs:{"return-object":"","dense":"","item-text":"name","item-value":"name","items":_vm.script_templates,"label":"Script Templates"},on:{"change":_vm.onChangeScriptTemplate},model:{value:(_vm.script_template),callback:function ($$v) {_vm.script_template=$$v},expression:"script_template"}})],1)],1),_c(VCard,[_c(VCardText,[_c('form',[_c('label',[_vm._v("Name")]),_vm._v(": "),_c(VTextField,{attrs:{"type":"text","placeholder":"Script name"},model:{value:(_vm.instance_name),callback:function ($$v) {_vm.instance_name=$$v},expression:"instance_name"}}),_c('label',[_vm._v("Description")]),_vm._v(": "),_c(VTextField,{attrs:{"type":"text","placeholder":"Script description"},model:{value:(_vm.script_description),callback:function ($$v) {_vm.script_description=$$v},expression:"script_description"}}),_c('label',[_vm._v("Cron")]),_vm._v(": "),_c(VTextField,{attrs:{"type":"text","placeholder":"Script cron"},model:{value:(_vm.script_cron),callback:function ($$v) {_vm.script_cron=$$v},expression:"script_cron"}}),_vm._l((_vm.schema),function(field,index){return _c(field.param_type,_vm._b({key:index,tag:"component",attrs:{"placeholder":field.placeholder},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}},'component',field,false))}),_c('p',[_vm._v("Fields: "+_vm._s(_vm.formData))]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.submit}},[_vm._v("Submit")])],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }